import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Table, Button, Modal } from 'react-bootstrap';
import './SmpScheduleHistory.css';

const SmpScheduleHistory = () => {
    const [schedules, setSchedules] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedSchedule, setSelectedSchedule] = useState(null);

    const fetchSchedules = async () => {
        try {
            const firmid = Cookies.get('firmid');
            const userid = Cookies.get('userid');
            const response = await axios.get(`/api/longterm/schedules?firmid=${firmid}&userid=${userid}`);
            setSchedules(response.data);
        } catch (error) {
            console.error('Error fetching schedules:', error);
        }
    };

    const fetchTasks = async (scheduleId) => {
        try {
            const response = await axios.get(`/api/tasks/${scheduleId}`);
            setTasks(response.data);
            setSelectedSchedule(scheduleId);
            setShowModal(true);
        } catch (error) {
            console.error('Error fetching tasks:', error);
        }
    };

    useEffect(() => {
        fetchSchedules();
    }, []);

    return (
        <div className="smp-schedule-history-container">
            <h2 className="header">User Schedules</h2>
            <Table className="table" striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Schedule Type</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Posting Time</th>
                        <th>Use Logo</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {schedules.map((schedule) => (
                        <tr key={schedule.ID}>
                            <td>{schedule.ID}</td>
                            <td>{schedule.SCHEDULE_TYPE}</td>
                            <td>{schedule.START_DATE}</td>
                            <td>{schedule.END_DATE}</td>
                            <td>{schedule.POSTING_TIME}</td>
                            <td>{schedule.USE_LOGO}</td>
                            <td>
                                <Button
                                    className="button button-primary"
                                    onClick={() => fetchTasks(schedule.ID)}
                                >
                                    View Tasks
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header className="modal-header" closeButton>
                    <Modal.Title className="modal-title">
                        Tasks for Schedule ID: {selectedSchedule}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <Table className="table" striped bordered hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Task to Automate</th>
                                <th>Day</th>
                                <th>Category ID</th>
                                <th>Holiday ID</th>
                                <th>Holiday Image ID</th>
                                <th>Caption ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tasks.map((task) => (
                                <tr key={task.ID}>
                                    <td>{task.ID}</td>
                                    <td>{task.TASK_TO_AUTOMATE}</td>
                                    <td>{task.DAY}</td>
                                    <td>{task.CATEGORY_ID}</td>
                                    <td>{task.HOLIDAY_ID}</td>
                                    <td>{task.HOLIDAY_IMAGE_ID}</td>
                                    <td>{task.CAPTION_ID}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <Button
                        className="button button-secondary"
                        onClick={() => setShowModal(false)}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SmpScheduleHistory;
