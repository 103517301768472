import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import './SmpLongTermSchedulerHome.css';
import PageLog from '../../PageLog/PageLog';

const SmpLongTermSchedulerHome = () => {
    const [frequency, setFrequency] = useState('DAILY');
    const [imageOption, setImageOption] = useState('my_image');
    const [dayOfWeek, setDayOfWeek] = useState('SUNDAY');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [error, setError] = useState('');
    const [hasActiveWatermark, setHasActiveWatermark] = useState(null);
    const [showWatermarkConfig, setShowWatermarkConfig] = useState(false); // New state for checkbox
    const [postingTime, setPostingTime] = useState("");

    const navigate = useNavigate();
    const location = useLocation(); // Detects navigation changes
    const userid = Cookies.get('userid');
    const firmid = Cookies.get('firmid');
    const portalid = Cookies.get("portalid");
    const today = new Date().toISOString().split('T')[0];

    useEffect(() => {
        // Redirect to login if the user is not logged in
        if (!userid || !firmid) {
            alert('Please log in.');
            navigate('/login');
        } else {
            // Check watermark status


            fetchWatermarkStatus();
        }
    }, [userid, firmid, navigate]);

    const fetchWatermarkStatus = async () => {
        try {
            const response = await axios.get('/api/watermark-status', {
                params: { userid, firmid },
            });
            setHasActiveWatermark(response.data.hasActiveWatermark);
        } catch (err) {
            console.error('Error fetching watermark status:', err);
            setError('Failed to fetch watermark status.');
        }
    };

    useEffect(() => {

        fetchWatermarkStatus();

    }, [location.pathname]);

    const handleCheckboxChange = () => {
        setShowWatermarkConfig(!showWatermarkConfig);
        fetchWatermarkStatus(); // Call fetchWatermarkStatus every time checkbox is clicked
    };


    const watermarkConfig = showWatermarkConfig ? "Y" : "N";



    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!startDate || !endDate) {
            setError('Both start and end dates must be selected.');
            return;
        }

        try {
            const response = await axios.post('/api/schedule', {
                userid,
                firmid,
                portalid,
                scheduleType: frequency,
                startDate,
                endDate,
                postingTime,
                dayOfWeek,
                imageOption,
                watermarkConfig,
            });

            alert(`Schedule and task parameters added successfully with ID: ${response.data.id}`);
        } catch (err) {
            console.error('Error submitting schedule:', err);
            setError('Failed to submit schedule. Please try again.');
        }
    };

    return (
        <div className="scheduler-container">
              <PageLog />
            <form onSubmit={handleSubmit} className="scheduler-form">
                <div className="form-group">
                    <label>Frequency:</label>
                    <select value={frequency} onChange={(e) => setFrequency(e.target.value)}>
                        <option value="DAILY">Daily</option>
                        <option value="WEEKLY">Weekly</option>
                        <option value="MONTHLY">Monthly</option>
                    </select>
                </div>

                {frequency !== 'DAILY' && (
                    <div className="form-group">
                        <label>Day of Week:</label>
                        <select value={dayOfWeek} onChange={(e) => setDayOfWeek(e.target.value)}>
                            <option value="SUNDAY">Sunday</option>
                            <option value="MONDAY">Monday</option>
                            <option value="TUESDAY">Tuesday</option>
                            <option value="WEDNESDAY">Wednesday</option>
                            <option value="THURSDAY">Thursday</option>
                            <option value="FRIDAY">Friday</option>
                            <option value="SATURDAY">Saturday</option>
                        </select>
                    </div>
                )}

                <div className="form-group">
                    <label>Image Option:</label>
                    <select value={imageOption} onChange={(e) => setImageOption(e.target.value)}>
                        <option value="my_image">My Images</option>
                        <option value="holiday_image">Holiday Images</option>
                        <option value="category_image">Stock Images</option>
                    </select>
                </div>

                <div className="form-group">
                    <label>Start Date:</label>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        min={today}
                    />
                </div>

                <div className="form-group">
                    <label>End Date:</label>
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        min={startDate || today}
                    />
                </div>


                <div className="form-group">
                    <label>Posting Time:</label>
                    <input
                        type="time"
                        value={postingTime}
                        onChange={(e) => setPostingTime(e.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label className="checkbox-label">
                        <input
                            type="checkbox"
                            checked={showWatermarkConfig}
                            onChange={handleCheckboxChange}
                        />
                        use company logo in all images
                    </label>
                </div>


                {/* Show watermark check and link if checkbox is checked and watermark is not active */}
                {showWatermarkConfig && hasActiveWatermark === false && (
                    <div className="watermark-config-link-container">
                        <p>Logo not added</p>
                        <a href="/logoaddingconfig" className="watermark-config-link">
                            Add Logo
                        </a>
                    </div>
                )}

                <button type="submit">Submit</button>
                {error && <div className="error-message">{error}</div>}
            </form>
        </div>
    );
};

export default SmpLongTermSchedulerHome;
