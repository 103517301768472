import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';

const TestQMHome = () => {
  const [mainHeads, setMainHeads] = useState([]);
  const vendorId = 5;

  const navigate = useNavigate();

  const userid = Cookies.get("userid");
  console.log("userid", userid);

  useEffect(() => {
    if (!userid) {
      alert("Please login");
      navigate('/login');
    }
  }, [userid, navigate]);

  // Fetch main heads data from the backend using Axios
  useEffect(() => {
    axios.get('/api/main-heads')
      .then((response) => {
        setMainHeads(response.data);
      })
      .catch((error) => {
        console.error('Error fetching main heads data:', error);
      });
  }, []);

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h1>Welcome to TestQM Home</h1>
      <nav>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          {mainHeads.map((head, index) => (
            <li key={index} style={{ margin: '10px 0' }}>
              <Link
                to={`/testquestionsmain/${head.MAIN_HEAD}`} // Navigate to the questions page based on main head
                style={{ textDecoration: 'none', color: '#007bff' }}
              >
                {head.MAIN_HEAD}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default TestQMHome;
