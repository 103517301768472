import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import './testqm.css'
import Cookies from 'js-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import PageLog from '../../PageLog/PageLog';

function TestAnswerBox() {


    let { topicId, questionId } = useParams();
    questionId = parseInt(questionId, 10)
    const location = useLocation();
    // const queryParams = new URLSearchParams(location.search);
    // const question = queryParams.get("question");
    const userid = Cookies.get("userid")
    const role_id = Cookies.get("role_id")
    const firmid = Cookies.get("firmid")
    const [tempq, setTempq] = useState(questionId);


    const [answer, setAnswer] = useState({
        answer_summary: '',
        answer_detail: ''
    });
    const [answerAxios, setAnswerAxios] = useState('');
    const [allquestions, getQuestions] = useState('');

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    const navigate = useNavigate()




    //console.log("userid", userid)

    //console.log("role_id", role_id)

    useEffect(() => {
        //       
        getdata();
        axios.post("/api/getquestions", { "id": topicId, "firmid": firmid, "role_id": role_id })
            .then((response) => {
                getQuestions(response.data);
                console.log("all questions", response.data);

                if (response.data.length > 0) {
                    const indexOfQuestion = response.data.findIndex(question => question.Q_ID === questionId);
                    //console.log("indexOfQuestion", indexOfQuestion)
                    setCurrentQuestionIndex(indexOfQuestion >= 0 ? indexOfQuestion : 0);
                }

                //console.log("id", id);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });


    }, []);



    useEffect(() => {


        //console.log("tempq", tempq);

        axios.get(`/api/answer/${topicId}/${tempq}/${userid}`)
            .then((response) => {

                console.log("all answers", response.data);

                if (response.data.length > 0) {
                    const indexOfQuestion = response.data.findIndex(question => question.Q_ID === tempq);
                    console.log("indexOfQuestion", indexOfQuestion)
                    //setCurrentQuestionIndex(indexOfQuestion);

                    if (indexOfQuestion != -1) {
                        setAnswer({
                            answer_summary: response.data[indexOfQuestion].ANSWER_SUMMARY,
                            answer_detail: response.data[indexOfQuestion].ANSWER_DETAIL,
                        })
                        console.log("sum", response.data[indexOfQuestion].ANSWER_SUMMARY,)
                        console.log("det", response.data[indexOfQuestion].ANSWER_DETAIL)
                        setAnswerAxios(1)
                    }
                    else {
                        setAnswerAxios(null)
                        setAnswer({
                            answer_summary: '',
                            answer_detail: '',
                        })
                    }

                }


            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [tempq]);

    console.log("tempq", tempq);

    //console.log("ans ",answerAxios)



    const getdata = async () => {
        try {
            //debugger;

            const response = await axios.get(`/api/answer/${topicId}/${tempq}/${userid}`);

            console.log("all answers in getdata", response.data);

            if (response.data.length > 0) {
                const indexOfQuestion = response.data.findIndex(question => question.Q_ID === tempq);
                console.log("indexOfQuestion", indexOfQuestion);

                if (indexOfQuestion !== -1) {
                    setAnswerAxios(1);
                }
            }
            else {
                setAnswerAxios(null);
                setAnswer({
                    answer_summary: '',
                    answer_detail: '',
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleAnswerChange = (event, field) => {
        if (field === 'answer_summary') {
            setAnswer({ ...answer, answer_summary: event.target.value });
        } else if (field === 'answer_detail') {
            setAnswer({ ...answer, answer_detail: event.target.value });
        }
    };

    const handleNext = () => {
        if (currentQuestionIndex < allquestions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
        setTempq(prevTempq => {

            return prevTempq + 1;
        });
        console.log("answer ", answer)
        getdata();
    };

    const handlePrevious = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
        setTempq(prevTempq => {
            return prevTempq - 1;
        });
        console.log("answer ", answer)
        getdata();
    };


    const submitAnswer = () => {
        // Create a data object with the answer
        const data = {
            answer_summary: answer.answer_summary,
            answer_detail: answer.answer_detail,
            userid: userid,
            topicId: topicId,
            questionId: tempq

            // You can add more data here if needed
        };
        // alert("Submitting answer")

        // Make an Axios POST request to send the answer

        // axios.post('/submit/answer', data)
        axios.post('http://61.2.142.91:7512/submit/answer', data)        
            .then(response => {
                // Handle the response from the server as needed
                console.log('Answer submitted successfully', response);
                
                getdata();
                //let nextq = questionId + 1
                navigate(`/qmquestion/${topicId}`);

            })
            .catch(error => {
                // Handle any errors that occur during the request
                console.error('Error submitting answer', error);
            });


    };


    // console.log("allquestions", allquestions);
    console.log("currentQuestionIndex", currentQuestionIndex);
    // console.log("questionId", questionId);



    return (
        <div>
            <PageLog />

            {allquestions.length > 0 && currentQuestionIndex < allquestions.length ? (
                <div>
                    {console.log("ans ", answerAxios)}
                    {answerAxios == null ?
                        (

                            <section >

                                <section className='testqm-form-container'>
                                    <h2>Question:</h2>

                                    <p>{allquestions[currentQuestionIndex].QUESTION}</p>

                                    <h2>Your Answer:</h2>
                                    <br />
                                    <label>{allquestions[currentQuestionIndex].ANSWER_SUMMARY}</label>
                                    <textarea
                                        value={answer.answer_summary}
                                        onChange={(event) => handleAnswerChange(event, 'answer_summary')}
                                        // placeholder='Answer Summary'
                                        className='textarea-summary'

                                    />
                                    <br /> <br />
                                    <label>{allquestions[currentQuestionIndex].ANSWER_DETAIL}</label>
                                    <textarea
                                        value={answer.answer_detail}
                                        onChange={(event) => handleAnswerChange(event, 'answer_detail')}
                                        // placeholder='Answer Details'
                                        className='textarea-detail'
                                    />

                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className='left-buttons'>
                                            <button className='btn btn-primary' style={{ marginLeft: 'auto' }} onClick={handlePrevious}
                                                disabled={true}
                                            //disabled={currentQuestionIndex === 0}

                                            >Previous</button>
                                        </div>
                                        <button className='testqm-button' onClick={submitAnswer}>Submit</button>
                                        <div className='right-buttons'>
                                            <button className='btn btn-primary' style={{ marginRight: 'auto' }} onClick={handleNext}

                                                //disabled={currentQuestionIndex === allquestions.length - 1}
                                                disabled={true}
                                            >Next</button>
                                        </div>
                                    </div>
                                </section>

                            </section>

                        ) :

                        (


                            <section className='testqm-form-container'>
                                <h2>Question:</h2>

                                <p>{allquestions[currentQuestionIndex].QUESTION}</p>

                                <h2>Your Answer:</h2>
                                <br />
                                <label>{allquestions[currentQuestionIndex].ANSWER_SUMMARY}</label>
                                <textarea
                                    value={answer.answer_summary}
                                    onChange={handleAnswerChange}
                                    // placeholder='Answer Summary'
                                    className='textarea-summary'
                                    disabled={true}
                                />
                                <br /> <br />
                                <label>{allquestions[currentQuestionIndex].ANSWER_DETAIL}</label>
                                <textarea
                                    value={answer.answer_detail}
                                    onChange={handleAnswerChange}
                                    // placeholder='Answer Details'
                                    className='textarea-detail'
                                    disabled={true}
                                />
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className='left-buttons'>
                                        <button className='btn btn-primary' style={{ marginLeft: 'auto' }}
                                            disabled={true}
                                            //disabled={currentQuestionIndex === 0}
                                            onClick={handlePrevious}>Previous</button>
                                    </div>
                                    <button className='testqm-button' disabled={true}>Submitted</button>
                                    <div className='right-buttons'>
                                        <button className='btn btn-primary' style={{ marginRight: 'auto' }}
                                            disabled={true}
                                            //disabled={currentQuestionIndex === allquestions.length - 1}
                                            onClick={handleNext}>Next</button>
                                    </div>
                                </div>



                            </section>

                        )

                    }

                </div>)
                : (
                    <p>No questions available</p>)
            }

        </div>
    )
}

export default TestAnswerBox